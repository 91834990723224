import React from "react";
import { servicesPageImg } from "../assets";
import { servicesList } from "../constants";

const Services = () => {
  const slideStylesWidthBackground = {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${servicesPageImg})`,
  };

  return (
    <main
      className=" bg-white min-h-screen w-screen  flex items-center justify-center"
      style={slideStylesWidthBackground}
    >
      <div
        className=" h-full w-full lg:h-screen lg:w-screen flex flex-col items-center "
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          margin: "auto",
          placeItems: "center",
          paddingTop: "10px",
          paddingBottom: "20px",
        }}
      >
        <h1 className="text-3xl font-bold text-white p-5">Services</h1>

        <div className="flex flex-col lg:flex-row lg:gap-x-10 items-center gap-y-6 static">
          {servicesList.map((item, index) => (
            <div
              className=" h-60 w-60 lg:h-72 lg:w-72 "
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url(${item.imageUrl})`,
              }}
            >
              <div
                className="flex flex-col justify-center items-center"
                style={{
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  background: "rgba(0, 0, 0, 0.5)",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <h2 className=" text-white pb-2">{item.title}</h2>
                <hr
                  style={{
                    backgroundColor: "white",
                    height: 3,
                    width: "70%",
                  }}
                />
                <p className=" text-white text-center pt-2">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Services;
