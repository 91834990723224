import homePageImg from "./beach-tree-boat.jpeg";
import servicesPageImg from "./coconut-tree.jpeg";
import contactPageImg from "./beach-coconut.jpeg";
import aboutPageImg from "./boat-coconut-tree.jpeg";
import closeIcon from "./close.svg";
import menuIcon from "./menu.svg";
import instagramIcon from "./instagram-fill.svg";
import locationImg from "./location-on.svg";
import whatsAppImg from "./whatsapp-fill.svg";
import emailImg from "./baseline-email.svg";
import profilePic from "./woman.jpeg";
import facialImg from "./facial.jpeg";
import massageImg from "./massage1.jpeg";
import maniPediImg from "./mani-pedi.jpeg";
import facebookImg from "./facebook.svg";
import telephoneImg from "./telephone-fill.svg";
import beachImage1 from "./sliderImages/image1beach.jpeg";

import beachImage2 from "./sliderImages/image2beach.jpeg";

import beachImage3 from "./sliderImages/image3beach.jpeg";

import beachImage4 from "./sliderImages/image4beach.jpeg";

import beachImage5 from "./sliderImages/image5beach.jpeg";

import beachImage6 from "./sliderImages/image6beach.jpeg";

import beachImage7 from "./sliderImages/image7beach.jpeg";

import beachImage8 from "./sliderImages/image8beach.jpeg";

import beachImage9 from "./sliderImages/image9beach.jpeg";

import beachImage10 from "./sliderImages/image10beach.jpeg";

import tropicalSpa from "./TropicalSpa.jpg";

import beachImage11 from "./sliderImages/image11beach.jpeg";
import siteLogo from "./spa.svg";
import servicesPDF from "./Available Services.pdf";

export {
  homePageImg,
  servicesPageImg,
  contactPageImg,
  aboutPageImg,
  closeIcon,
  menuIcon,
  instagramIcon,
  locationImg,
  whatsAppImg,
  emailImg,
  profilePic,
  maniPediImg,
  facialImg,
  massageImg,
  facebookImg,
  siteLogo,
  telephoneImg,
  servicesPDF,
  tropicalSpa,
};

export const sliderImages = [
  {
    url: beachImage1,
    title: "beachImage1",
  },
  {
    url: beachImage2,
    title: "beachImage2",
  },
  {
    url: beachImage3,
    title: "beachImage3",
  },
  {
    url: beachImage4,
    title: "beachImage4",
  },
  {
    url: beachImage5,
    title: "beachImage5",
  },
  {
    url: beachImage6,
    title: "beachImage6",
  },
  {
    url: beachImage7,
    title: "beachImage7",
  },
  {
    url: beachImage8,
    title: "beachImage8",
  },
  {
    url: beachImage9,
    title: "beachImage9",
  },
  {
    url: beachImage10,
    title: "beachImage10",
  },
  {
    url: beachImage11,
    title: "beachImage11",
  },
];
