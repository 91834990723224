import React from "react";
import { aboutPageImg, locationImg, tropicalSpa } from "../assets";

const About = () => {
  const slideStylesWidthBackground = {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${aboutPageImg})`,
  };
  return (
    <div
      className=" bg-white h-screen min-w-min flex items-center flex-col justify-center "
      style={slideStylesWidthBackground}
    >
      <div
        className="lg:h-full lg:w-full flex flex-col items-center justify-center "
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          margin: "auto",
          placeItems: "center",
          paddingTop: "20px",
        }}
      >
        <h1 className=" p-2 text-3xl font-bold text-white">
          About Tropical Spa
        </h1>
        <p className="p-2  h-auto text-center lg:text-xl lg:w-1/2 text-white">
          {" "}
          Tropical Spa is a Mobile Spa, it is a unique and blissful in home spa
          experience. This is the new way to enjoy relaxing day spa treatments
          but from the comfort of your own home. You still get all the benefits
          of a high end spa with our manicures, pedicures, massage, facials, and
          more. You get to be spoiled and pampered without the risk of smudging
          a nail, or even the drive home that can knock you out of your relaxing
          mode. Our staff members travel to your venue and accommodate your
          party or event. This is next level pampering we deliver with our
          Signature touch.
        </p>
        <div className=" p-4 flex items-center justify-center">
          <img src={locationImg} alt="location" />
          <p className="pl-2 lg:text-xl text-left text-white">
            St. Vincent and the Grenadines,
            <br />
            Bequia
          </p>
        </div>
        <p className="p-2 h-auto lg:text-xl lg:w-1/2 text-center text-white">
          Please find the areas we serve in Bequia at this time. Please note all
          appointments under $200 in sales per staff member sent are subject to
          a $10+ travel fee per practitioner. If you have any questions please
          feel free to <a href="mailto:kulture101@hotmail.com">EMAIL</a> or{" "}
          <a href="tel:123-456-7890">CALL</a> us today!
        </p>

        <div className=" flex flex-col items-center justify-center">
          <h2 className=" p-2 text-2xl font-bold text-center text-white">
            Meet the Tropical Spa Team
          </h2>
          <div className="w-52 h-52 rounded-full bg-slate-50 place-items-center overflow-hidden">
            <img src={tropicalSpa} alt="profile pic" />
          </div>
          <h3 className="p-2 text-center font-bold text-white">
            Sumenta, OWNER
          </h3>
          <p className="p-2 pb-4 h-auto lg:text-xl lg:w-1/2 text-center text-white">
            Sumenta has been in the spa industry practicing esthetics since
            2010. Esthetics has been her passion and finds pampering clients
            very rewarding and fulfilling. Her goal is to create the ultimate
            spa experience in the comfort of your home. She loves every aspect
            of organizing and pampering your spa day!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
