import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { navLinks } from "../constants";
import { menuIcon, closeIcon, siteLogo } from "../assets";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  // const [toggle, setToggle] = useState(false);

  const navbarOpen = () => {
    document.getElementById("navba").classList.add("fixed");
    // const scrollY =
    // document.documentElement.style.getPropertyValue("--scroll-y");
    const body = document.body;
    body.style.position = "fixed";
    // body.style.top = `-${scrollY}`;
  };

  const navbarClose = () => {
    const body = document.body;
    // const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    // window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.getElementById("navba").classList.remove("fixed");
  };
  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
  });

  return (
    <nav
      id="navba"
      className=" bg-[#72813e] w-full flex py-6 flex-row justify-between items-center sm:px-16 px-6 top-0"
    >
      <div className="">
        <NavLink
          to="/"
          className=" flex flex-row justify-center items-center text-white text-[24px] font-bold"
        >
          <img className=" w-8 h-8 mr-2" src={siteLogo} alt="logo" />
          <h2>Tropical Spa Bequia</h2>
        </NavLink>
      </div>
      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((item, index) => (
          <li key={item.id}>
            <NavLink
              to={`/${item.id}`}
              className={`text-white text-[16px] cursor-pointer font-bold ${
                index === navLinks.length - 1 ? "mr-0" : "mr-10"
              }`}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center ">
        <img
          src={toggle ? closeIcon : menuIcon}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => {
            setToggle((prev) => !prev);
            navbarOpen();
          }}
        />
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } bg-[#72813e] py-6 top-20 right-0  min-w-[140px] w-full h-full fixed`}
        >
          <ul className="list-none flex flex-col space-y-6 justify-center items-center flex-1">
            {navLinks.map((item, index) => (
              <li key={item.id}>
                <NavLink
                  to={`/${item.id}`}
                  className={`text-white text-[24px] font-bold ${
                    index === navLinks.length - 1 ? "mb-0" : "mb-4"
                  }`}
                  onClick={() => {
                    setToggle((prev) => !prev);
                    navbarClose();
                  }}
                >
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
