import { useState } from "react";

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const rightArrowStyles = {
  // position: "absolute",
  top: "50%",
  // transform: "translate(0, -50%)",
  right: "32px",
  paddingRight: "10px",
  fontSize: "45px",
  color: "#fff",
  // zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  // position: "absolute",
  top: "50%",
  // transform: "translate(0, -50%)",
  left: "32px",
  paddingLeft: "10px",
  fontSize: "45px",
  color: "#fff",
  // zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  height: "100%",
};
const arrowsContainerStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
};

// const dotsContainerStyles = {
//   display: "flex",
//   justifyContent: "center",
//   marginTop: "10px",
// };

// const dotStyle = {
//   margin: "0 3px",
//   cursor: "pointer",
//   fontSize: "20px",
//   color: "#fff",
// };

const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // const goToSlide = (slideIndex) => {
  //   setCurrentIndex(slideIndex);
  // };

  const slideStylesWidthBackground = {
    ...slideStyles,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // position: "relative",
    backgroundImage: `url(${slides[currentIndex].url})`,
  };

  return (
    <div style={sliderStyles}>
      <div style={slideStylesWidthBackground}>
        <div style={arrowsContainerStyle}>
          <div onClick={goToPrevious} style={leftArrowStyles}>
            ❰
          </div>
          <div onClick={goToNext} style={rightArrowStyles}>
            ❱
          </div>
        </div>
      </div>
      {/* <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            style={dotStyle}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ImageSlider;
