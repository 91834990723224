import React from "react";

const Footer = () => {
  return (
    <footer
      class="  text-center lg:text-left"
      style={{ backgroundColor: "#72813e" }}
    >
      <div class="text-white text-center p-4">
        © 2021 Copyright:
        <a class="text-gray-600" href="https://tailwind-elements.com/">
          {" "}
          RBM-CREATE
        </a>
      </div>
    </footer>
  );
};

export default Footer;
