import React from "react";
import SocialMedia from "./SocialMedia";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { contactPageImg, locationImg, emailImg, telephoneImg } from "../assets";

const Contact = () => {
  const slideStylesWidthBackground = {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${contactPageImg})`,
  };

  const inputFieldStyles = {
    backgroundColor: "#e2efff",
    borderRadius: "16px",
    height: "50px",
    width: "250px",
    color: "#72813e",
    paddingLeft: "8px",
  };

  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fbbufg3",
        "template_vkg3ni8",
        formRef.current,
        "user_PxHDwjJuTxlYr5C13CDOv"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div
      className=" bg-white h-screen w-screen max-w-full "
      style={slideStylesWidthBackground}
    >
      <div
        className=" h-full lg:h-screen flex flex-row items-center justify-center pt-10 pb-10 "
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          margin: "auto",
          placeItems: "center",
        }}
      >
        <div
          className=" lg:h-3/4 lg:w-3/4 flex flex-col-reverse md:flex-row md:bg-[#73813e9e]  items-center justify-evenly lg:rounded-xl  "
          // style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="flex flex-col space-y-8  text-white mt-10">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row items-center justify-left">
                <img src={telephoneImg} alt="telephoneImg" />
                <a
                  className="pl-2 lg:text-xl text-left"
                  href="tel:123-456-7890"
                >
                  123-456-7890
                </a>
              </div>
              <div className="flex flex-row items-center justify-left">
                <img src={emailImg} alt="emailImg" />
                <a
                  className="pl-2 lg:text-xl text-left"
                  href="mailto:contact@example.com"
                >
                  contact@example.com
                </a>
              </div>
              <div className="flex flex-row items-center justify-left">
                <img src={locationImg} alt="location" />
                <p className="pl-2 lg:text-xl text-left  ">
                  St. Vincent and the Grenadines, <br />
                  Bequia.
                </p>
              </div>
            </div>

            <SocialMedia />
          </div>

          <div>
            <h1 className=" text-4xl p-3 font-bold text-white">Contact Us!</h1>
            <form
              className=" flex flex-col space-y-4"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <input
                style={inputFieldStyles}
                type="text"
                placeholder="Name*"
                name="user_name"
              />
              <input
                style={inputFieldStyles}
                type="text"
                placeholder="Subject*"
                name="user_subject"
              />
              <input
                style={inputFieldStyles}
                type="text"
                placeholder="Email*"
                name="user_email"
              />
              <textarea
                style={{
                  backgroundColor: "#e2efff",
                  borderRadius: "16px",
                  height: "150px",
                  paddingLeft: "8px",
                  paddingTop: "8px",
                  color: "#72813e",
                  borderBottom: "3px solid #72813e",
                }}
                rows="5"
                placeholder="Message*"
                name="message"
              />
              <button
                className="shadow-lg h-14 bg-white rounded-xl shadow-gray-400 hover:scale-110 ease-in duration-300"
                style={{
                  backgroundColor: "#e2efff",
                  color: "#72813e",
                }}
              >
                <h3 className=" font-bold">Submit</h3>
              </button>
              {done && "Thank you..."}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
