import React from "react";
import { socialMedia } from "../constants";

const SocialMedia = () => {
  return (
    <div className="flex items-center justify-center">
      {socialMedia.map((item, index) => (
        <div
          className="w-[50px] h-[50px] flex m-6 rounded-xl shadow-lg shadow-gray-400 p-1 cursor-pointer hover:scale-110 ease-in duration-300"
          style={{
            background: "rgba(255, 255, 255, 0.7)",
          }}
          key={index}
        >
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <img src={item.icon} alt={item.id} />
          </a>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;
