import {
  instagramIcon,
  whatsAppImg,
  // emailImg,
  maniPediImg,
  facialImg,
  massageImg,
  facebookImg,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "about",
    title: "About",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagramIcon,
    link: "https://www.instagram.com/the_tropical_spa/",
  },
  {
    id: "social-media-2",
    icon: whatsAppImg,
    link: " https://wa.me/7844916013",
  },
  {
    id: "social-media-3",
    icon: facebookImg,
    link: "https://www.facebook.com/jeraldine.mc",
  },
];

export const servicesList = [
  {
    id: "Services1",
    title: "Massages",
    imageUrl: massageImg,
    content:
      "Massages help relax muscles, increase the flow of blood & oxygen, and decrease pain. They’re an effective treatment for reducing stress & tension. Spa Theory offers a range of massage techniques.",
  },
  {
    id: "Services2",
    title: "Facials",
    imageUrl: facialImg,
    content:
      "A facial is one of the best ways to take care of your skin, it cleanses, nourishes, and exfoliates the skin. Enjoy one in the comfort of your home,",
  },
  {
    id: "Services3",
    title: "Nails",
    imageUrl: maniPediImg,
    content:
      "Includes manicures and pedicures that show love to your fingers and toes with shaping and perfect polishes.",
  },
];

export const aboutPageData = [
  {
    id: "AboutFirstP",
    content:
      " Tropical Spa is a Mobile Spa, it is a unique and blissful in home spa experience. This is the new way to enjoy relaxing day spa treatments but from the comfort of your own home. You still get all the benefits of a high end spa with our manicures, pedicures, massage, facials, and more. You get to be spoiled and pampered without the risk of smudging a nail, or even the drive home that can knock you out of your relaxing mode. Our staff members travel to your venue and accommodate your party or event. This is next level pampering we deliver with our Signature touch.",
  },
  {
    id: "AboutSecondP",
    content:
      "Please find the areas we serve in the lower mainland at this time. Please note all appointments under $200 in sales per staff member sent are subject to a $10+ travel fee per practitioner. If you have any questions please feel free to Email or Call us today!",
  },
  {
    title: "Meet The Tropical Spa Team",
    team: [
      {
        name: "Sumenta",
        imageUrl: "",
        title: "Owner",
        about:
          "Karen has been in the spa industry practicing esthetics since 2010. Esthetics has been her passion and finds pampering clients very rewarding and fulfilling.  Her goal is to create the ultimate spa experience in the comfort of your home. She loves every aspect of organizing and pampering your spa day!",
      },
    ],
  },
];

// export const footerLinks = [
//     {
//       title: "Useful Links",
//       links: [
//         {
//           name: "Content",
//           link: "https://www.hoobank.com/content/",
//         },
//         {
//           name: "How it Works",
//           link: "https://www.hoobank.com/how-it-works/",
//         },
//         {
//           name: "Create",
//           link: "https://www.hoobank.com/create/",
//         },
//         {
//           name: "Explore",
//           link: "https://www.hoobank.com/explore/",
//         },
//         {
//           name: "Terms & Services",
//           link: "https://www.hoobank.com/terms-and-services/",
//         },
//       ],
//     },
//     {
//       title: "Community",
//       links: [
//         {
//           name: "Help Center",
//           link: "https://www.hoobank.com/help-center/",
//         },
//         {
//           name: "Partners",
//           link: "https://www.hoobank.com/partners/",
//         },
//         {
//           name: "Suggestions",
//           link: "https://www.hoobank.com/suggestions/",
//         },
//         {
//           name: "Blog",
//           link: "https://www.hoobank.com/blog/",
//         },
//         {
//           name: "Newsletters",
//           link: "https://www.hoobank.com/newsletters/",
//         },
//       ],
//     },
//     {
//       title: "Partner",
//       links: [
//         {
//           name: "Our Partner",
//           link: "https://www.hoobank.com/our-partner/",
//         },
//         {
//           name: "Become a Partner",
//           link: "https://www.hoobank.com/become-a-partner/",
//         },
//       ],
//     },
//   ];

// export const feedback = [
//     {
//       id: "feedback-1",
//       content:
//         "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
//       name: "Herman Jensen",
//       title: "Founder & Leader",
//       img: people01,
//     },
//     {
//       id: "feedback-2",
//       content:
//         "Money makes your life easier. If you're lucky to have it, you're lucky.",
//       name: "Steve Mark",
//       title: "Founder & Leader",
//       img: people02,
//     },
//     {
//       id: "feedback-3",
//       content:
//         "It is usually people in the money business, finance, and international trade that are really rich.",
//       name: "Kenn Gallagher",
//       title: "Founder & Leader",
//       img: people03,
//     },
//   ];
