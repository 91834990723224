import React from "react";
import { homePageImg, sliderImages, servicesPDF } from "../assets";
import ImageSlider from "./ImageSlider";
import SocialMedia from "./SocialMedia";

const Home = () => {
  const slides = sliderImages;

  // const containerStyles = {
  //   // width: "500px",
  //   // height: "280px",
  //   margin: "0 auto",
  // };

  const slideStylesWidthBackground = {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${homePageImg})`,
  };

  return (
    <main
      className=" bg-white min-h-screen  w-screen max-w-full flex items-center justify-center "
      style={slideStylesWidthBackground}
    >
      <div
        className=" h-screen w-screen flex flex-col items-center gap-y-6 static pt-10 "
        style={{
          background: "rgba(0, 0, 0, 0.5)",
          margin: "auto",
          placeItems: "center",
        }}
      >
        <div className="text-5xl text-white text-center">
          Tranquility Starts here, Embracing Nature within!
        </div>
        <div
          className="bg-[#72813e] text-5xl w-[240px] h-[60px] rounded-xl text-white text-center flex items-center justify-center"
          // style={{ background: " }}
        >
          <a href={servicesPDF} download="Available Services.pdf">
            Services
          </a>
        </div>
        <div className=" w-72 h-80 bg-white lg:mt-8 rounded-xl mx-0 lg:w-[1100px] lg:h-[900px] ">
          <ImageSlider slides={slides} />
        </div>
        <div className=" flex items-center justify-center">
          <SocialMedia />
        </div>
      </div>
    </main>
  );
};

export default Home;
